import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAppState } from "./../shared/store/app.store";
import { GuardService } from '../shared/helper-service/guard.service';
import { RouterService } from '../shared/helper-service/router.service';
import { Store } from '@ngrx/store';


@Injectable()
export class EditContactInformationGuard implements CanActivate {

  constructor(private guardService: GuardService,
    private routerService: RouterService,
    private store: Store<IAppState>) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let customerContact: any;
      this.store.select(state => state.CustomerContact).subscribe(x => customerContact = x);
      if (customerContact && Object.keys(customerContact).length)
              return this.guardService.guardSucceded();

      this.routerService.navigateToMyProfile();
      return false;
    }
}
