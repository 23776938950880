import { Subject } from 'rxjs';


export const Constants = {
  ServerLogLevel: "ServerLogLevel",
  User_Data: "User_Data",
  LanguageConcat: "-",
  MyProfile: "my-profile",
  LanguageParameterName: "language",
  SkipToMainFragment: "#main",
  // Sub Nav Types
  MyAccount: "My Account",
  My_Profile: "My Profile",
  MyGarage: "My Garage",
  BMWLogin: "BMW Login",
  EditLogin: "Edit Login",
  Home: "Home",
  USER_DATA: "User_Data",
  SESSION_ID: "sessionId",
  ENROLLED: "Enrolled",
  NOT_ENROLLED: "Not Enrolled",
  LOAN: "Loan",
  FINANCING: "Financing",
  CookiePolicy: "Cookie Policy",

  myGarageTileBmw: 'my-garage-bmw',
  myGarageTileMini: 'my-garage-mini',
  myAccountTile: 'my-account-bmw',
  EMPTY: "",
  Sso: "sso",

  //deeplinks
  redirect: "/redirect?",
  paramPageId1: "id1=",
  paramPageId2: "id2=",
  paramConcat: "&",
  MyAccountProfilePage: "pf",
  profileVerifyToken: "pt",
  TimeOutDialogId: "sessionInactivityDialog",
  SessionTimeOutId: "sessionTimeOutDialog",
  CommunicationPreferencesDialogId: "CommunicationPreferencesDialog",

  CommunicationPreferencesNA: "CommunicationPreferencesNA",
  CommunicationPreferencesFS: "CommunicationPreferencesFS",
  ErrorOccured: "Error Occured in",
  StatusCode: "Status Code:",
  MarketingPreferenceGet: "marketingpreferences.get",
  MarketingPreferencePut: "marketingpreferences.put",

  //User Type
  NaUser: "na",
  FsUser: "fs",

  //Regular Expression
  emailRegex: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
  phoneRegex: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
  zipRegex: new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/),

  //Other
  States: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"],

  //edit profile
  addressLine1MaxLength: 40,
  addressLine2MaxLength: 15,
  zipCodeMaxLength: 5,

  //Gateway Api Request options
  response: "response",
  responseTypeBlob: "blob",
  headerContentType: "application/json",
  headerAccept: "application/json",

  TID: "TID",

  LanguageParam: "language",
  UrlParameterNameAndValueConcat: "=",
  UrlParametersPrefix: "?"
}

export class Events {
  public static DirtyDataIndicator: Subject<any> = new Subject<any>();
}
