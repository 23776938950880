import { HttpClient } from "@angular/common/http";
import { Injectable, Component, OnDestroy, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router"
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ParentActivityIdActions } from 'projects/_shared/shared/store/reducers/parent-activity-id.reducer';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { StorageService } from 'customerdigital-service-lib';
import { UserActivityIdActions } from 'projects/_shared/shared/store/reducers/user-activityid.reducer';
import { ClientLogRequestLevel, ClientLogRequest, BeginActivityRequest } from '../gatewayapi/logger-service.api';
import { Constants } from 'projects/_shared/shared/constants';
import { ClientLogWriterService } from 'projects/_shared/web-service/client-log-writer.service';
import { StorageType } from 'customerdigital-service-lib';
import { Store } from "@ngrx/store";


@Injectable()
export class ActivityLoggingFactory {
  public parentActivityId: string|undefined;

  private loggers: ActivityLoggingComponent[];

  constructor(private router: Router,
    private http: HttpClient,
    private clientLogWriterService: ClientLogWriterService,
    private parentActivityIdActions: ParentActivityIdActions,
    private userActivityIdActions: UserActivityIdActions,
    private store: Store<IAppState>,
    private storageService: StorageService) {
    this.loggers = [];
  }

  getLogger(componentName:string, activityName: string, isAParent: boolean): Promise<ActivityLoggingComponent>  //TODO: We could store/cache instances per T and return existing if called for a repeat T
  {
    let _loggers = this.loggers;
    var idx = this.loggers.findIndex(r => r.sourceName === componentName );
    if (idx >= 0) {
      return Promise.resolve(this.loggers[idx]);
    }
    else {
      let newLogger = new ActivityLoggingComponent(this.router, this.http, this, this.clientLogWriterService, this.store, this.parentActivityIdActions, this.userActivityIdActions, this.storageService);
      newLogger.iAmAParent = isAParent;
      newLogger.sourceName = componentName;
      newLogger.activityName = activityName;

      return newLogger.ngOnInit()
        .then(rtrn => {
          if (idx < 0) {
            _loggers.push(rtrn);
          }
          return rtrn;
        });
    }
  }

  public loggerDestroyed(logger: ActivityLoggingComponent): void {
    this.loggers.splice(this.loggers.indexOf(logger), 1);
  }
}

//****************************************************************************************************************************************************************//
@Component({
  template:""
})
export class ActivityLoggingComponent implements ActivityLogging, OnDestroy, OnInit {
  activityName: string|undefined;
  sourceName: string|undefined;
  iAmAParent: boolean|undefined;

  private serverLogLevel: ClientLogRequestLevel|undefined;

  private activitySubscription: Subscription|undefined;

  private logLevelUpdateTimer: any;

  private sequenceNumber: number = 0;

  private activityId: string | undefined;

  constructor(
    private router: Router,
    private http: HttpClient,
    private factory: ActivityLoggingFactory,
    private clientLogWriterService: ClientLogWriterService,
    private store: Store<IAppState>,
    private parentActivityIdActions: ParentActivityIdActions,
    private userActivityIdActions: UserActivityIdActions,
    private storageService: StorageService) {
    this.activityId = this.getActivityId();
  }

  ngOnInit(): Promise<ActivityLoggingComponent> {
    let thisLogger = this;
    return thisLogger.updateServerLogLevel().then(function () {
      if (thisLogger.iAmAParent) {
        let parentActivityId: string = thisLogger.getActivityId() ? thisLogger.getActivityId() :"";
        thisLogger.factory.parentActivityId = parentActivityId ? parentActivityId:"";
        thisLogger.store.dispatch(thisLogger.parentActivityIdActions.setParentActivityId(parentActivityId));
      }
      thisLogger.beginNewActivity();
      return thisLogger;
    });
  }

  //*Could we make the log method return the current log level for frequent updating (and reset the refresh timer in the process)?
  private updateServerLogLevel(): Promise<ActivityLoggingComponent> {
    let thisLogger = this;

    let serverLogLvlSetting = this.storageService.getItem(Constants.ServerLogLevel, StorageType.session);

    if (serverLogLvlSetting) {

      let svrLogLevel: ClientLogRequestLevel = JSON.parse(serverLogLvlSetting);

      if (svrLogLevel) {
        thisLogger.serverLogLevel = svrLogLevel;
        return Promise.resolve(thisLogger);
      }
    }
    return this.clientLogWriterService.currentLevel().then(result => {
      thisLogger.serverLogLevel = this.getServerLogLevelEnum(result);
      this.storageService.setItem(Constants.ServerLogLevel, JSON.stringify(thisLogger.serverLogLevel), StorageType.session);
      return thisLogger;
    },
      error => {
        thisLogger.serverLogLevel = ClientLogRequestLevel.Informational;
        return thisLogger;
      });
  }

  private getServerLogLevelEnum(serverLogLevel: string) {
    let level;
    switch (serverLogLevel) {
      case "Critical":
        level = ClientLogRequestLevel.Critical; break;
      case "Error":
        level = ClientLogRequestLevel.Error; break;
      case "LogAlways":
        level = ClientLogRequestLevel.LogAlways; break;
      case "Verbose":
        level = ClientLogRequestLevel.Verbose; break;
      case "Warning":
        level = ClientLogRequestLevel.Warning; break;
      default:
        level = ClientLogRequestLevel.Informational; break;
    }
    return level;
  }

  private beginNewActivity(): Promise<any> {
    let thisLogger = this;
    return thisLogger.clientLogWriterService.beginNewActivity(thisLogger.buildNewActivityRequest())
      .then(result => {
        thisLogger.activityId = result;
      },
        _error => {
          thisLogger.serverLogLevel = ClientLogRequestLevel.Informational;
        });
  }

  logalways(args: any[]): void {
    this.logToServer(ClientLogRequestLevel.LogAlways, args);
  }

  logcritical(args: any[]): void {
    if (this.serverLogLevel && this.serverLogLevel >= ClientLogRequestLevel.Critical) {
      this.logToServer(ClientLogRequestLevel.Critical, args);
    }
  }

  logerror(args: any[]): void {
    if (this.serverLogLevel && this.serverLogLevel >= ClientLogRequestLevel.Error) {
      this.logToServer(ClientLogRequestLevel.Error, args);
    }
  }

  logwarning(args: any[]): void {
    if (this.serverLogLevel && this.serverLogLevel >= ClientLogRequestLevel.Warning) {
      this.logToServer(ClientLogRequestLevel.Warning, args);
    }
  }

  loginformational(args: any[]): void {
    if (this.serverLogLevel && this.serverLogLevel >= ClientLogRequestLevel.Informational) {
      this.logToServer(ClientLogRequestLevel.Informational, args);
    }
  }

  logverbose(args: any[]): void {
    if (this.serverLogLevel && this.serverLogLevel >= ClientLogRequestLevel.Verbose) {
      this.logToServer(ClientLogRequestLevel.Verbose, args);
    }
  }

  private logToServer(level: ClientLogRequestLevel, args: any[]): void {
    let isAppLoggingOn: boolean | undefined ;
    this.store.select(state => state.EnvironmentConfig?.APP_LOGGING_ENABLED).subscribe(x=>isAppLoggingOn = x);
    let isConsoleLoggingOn: boolean | undefined;
    this.store.select(state => state.EnvironmentConfig?.CONSOLE_LOGGING_ENABLED).subscribe(x=>isConsoleLoggingOn = x);    
    var clientLogRequest = this.buildClientLogRequest(level, args);
    if (isAppLoggingOn) {
      this.clientLogWriterService.log(clientLogRequest)
        .then(_result => { },
          error => console.log(error)
        );
    }
    if (isConsoleLoggingOn) {
      this.logToConsole(clientLogRequest);
    }
  }

  private logToConsole(clientLogRequest: ClientLogRequest): void {
    console.log(clientLogRequest);
  }

  //*need to refactor this to make it internal and cfg driven
  logAllActivity(logClientActivity: boolean = true): void {
    if (logClientActivity) {
      if (!this.activitySubscription || this.activitySubscription.closed) {
        this.activitySubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.loginformational([this.router.routerState.snapshot.url]);
          }
        });
      }
    }
    else {
      if (this.activitySubscription) {
        this.activitySubscription.unsubscribe();
      }
    }
  }

  private buildClientLogRequest(level: ClientLogRequestLevel, args?: any[]): ClientLogRequest {
    var request = new ClientLogRequest();
    request.level = level;
    request.activityId = this.activityId;
    request.seqNo = ++this.sequenceNumber;
    request.service = this.sourceName;
    request.url = this.router.url;
    request.arguments = args;

    return request
  }

  private buildNewActivityRequest(): BeginActivityRequest {
    var data = new BeginActivityRequest();
    data.name = this.activityName;
    data.activityId = this.activityId;
    data.relatedActivityId = this.factory.parentActivityId;
    data.seqNo = ++this.sequenceNumber;
    data.url = this.router.url;

    return data;
  }

  getActivityId(): any {
    let userActivityId: any;
    this.store.select(state => state.UserActivityId).subscribe(x => userActivityId =x);
    if (userActivityId != undefined && userActivityId && userActivityId != "") {
      this.activityId = userActivityId ? userActivityId?.stringValue : "";
    } else {
      this.activityId = uuidv4();
      // set the activity id in session here
      this.store.dispatch(this.userActivityIdActions.setUserActivityId(this.activityId));
    }
    return this.activityId;
  }


  ngOnDestroy(): void {
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }

    if (this.logLevelUpdateTimer && this.logLevelUpdateTimer.data) {
      clearInterval(this.logLevelUpdateTimer.data.handleId);
    }

    this.factory.loggerDestroyed(this);
  }
}

export interface ActivityLogging {
  ngOnInit(): Promise<ActivityLoggingComponent>;

  logalways(args: any[]): void;
  logcritical(args: any[]): void;
  logerror(args: any[]): void;
  logwarning(args: any[]): void;
  loginformational(args: any[]): void;
  logverbose(args: any[]): void;

  getActivityId(): string | undefined;

  logAllActivity(logClientActivity: boolean): void;
}
