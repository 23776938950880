import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GuardService } from '../shared/helper-service/guard.service';
import { route } from '../shared/helper-service/router.service';
import { UserService } from '../shared/helper-service/user.service';
@Injectable()
export class NotTermsGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService, private guardService: GuardService) { }

    canActivate(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      this.guardService.setLanguage(state);

      if (!this.userService.isTermsAccepted())
            return this.guardService.guardSucceded(); 
      else 
            return this.guardService.guardFailed(route.baseUrl, false, activeRoute.params);
    }
}
