import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IAppState } from "./../shared/store/app.store";
import { UserService } from '../shared/helper-service/user.service';
import { GuardService } from '../shared/helper-service/guard.service';
import { Store } from '@ngrx/store';

@Injectable()
export class CheckDeviceTokenGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService, private guardService: GuardService, private store: Store<IAppState>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.userService.isAuthenticated()) {
      return this.guardService.guardFailedForCheckDevice("login", state.url);
    }
    let deviceTokenValue = this.userService.getDeviceToken();
    if ((route.queryParams.devicetoken != undefined || deviceTokenValue != "") || this.userService.customerDeviceTokenExists()) {
      let deviceToken: string = ((deviceTokenValue != "" && deviceTokenValue != undefined ) ? deviceTokenValue : route.queryParams.devicetoken);
      if (deviceToken != undefined && deviceToken != "") {
          this.userService.setDeviceTokenChecked(true, deviceToken);
        }
        else {
          this.userService.setDeviceTokenChecked(false, deviceToken);
        }
      return this.guardService.guardSucceded();
    }
    else {
      let deviceTokenURL: string | undefined;
      let myProfileUrl: string | undefined;
      this.store.select(state => state.EnvironmentConfig!.MYPROFILE_URL).subscribe(x => myProfileUrl = x);
      this.store.select(state => state.EnvironmentConfig?.DEVICE_TOKEN_URL).subscribe(x => deviceTokenURL = x);
      return this.guardService.guardFailedForDeviceToken(deviceTokenURL + "/verifytoken", true, { gcid: this.userService.getGcid(), state: myProfileUrl! + route.routeConfig!.path });;
    }
  }
}