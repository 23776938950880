import { Injectable, Injector } from '@angular/core';
import { IAppState } from "../store/app.store";
import * as _ from 'lodash-es';
import { FeatureIndicatorService } from '../../web-service/feature-indicator.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EpaasConfig, EpaasService } from 'customerdigital-service-lib';
import { FeatureIndicatorActions } from '../store/reducers/feature-indicator.reducers';
import { BooleanModel } from '../../models/boolean.model';
import { Observable } from 'rxjs';
import { IsEpassInitializedActions } from '../store/reducers/is-epaas-initialized.reducer';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

declare var epaas: any;

@Injectable()
export class EpaasHelperService {

  constructor(
    private translateService: TranslateService,
    private epaasService: EpaasService,
    private featureIndicatorActions: FeatureIndicatorActions,
    private store: Store<IAppState>,
    private injector: Injector) {
  }

  public initializeEpaas(): void {
    let epaasConfig = this.getEpaasConfig();
    let epaasFeatureIndicatorType : string | undefined;
    this.store.select(x=> x.ApplicationConfig?.EPAAS_FEATURE_TYPE).subscribe(s=> epaasFeatureIndicatorType = s)
    let featureIndicatorService = this.injector.get(FeatureIndicatorService);
    featureIndicatorService.getFeatureIndicatorByClientId(epaasFeatureIndicatorType!)
      .subscribe(response => this.postGetFeatureIndicator(response, epaasConfig));
  }

  public setLanguage() {
    this.epaasService.changeLanguage(this.getEpaasLocale);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.epaasService.changeLanguage(this.getEpaasLocale);
    });
  }

  get getEpaasLocale(): string {
   let epaasLocale: any;
    this.translateService.get("epaas.locale").subscribe((result: string) => { epaasLocale = result });
    return epaasLocale;
  }

  private getEpaasConfig(): EpaasConfig {
    let epaasScriptUrl: string | undefined;
    let epaasTenantId: string | undefined;  
    this.store.select(x=> x.EnvironmentConfig?.EPAAS_SCRIPT_URL).subscribe(s=> epaasScriptUrl = s);
    this.store.select(x=> x.ApplicationConfig?.EPAAS_TENANT_ID).subscribe(s=> epaasTenantId = s);    
    return new EpaasConfig(epaasScriptUrl, epaasTenantId, this.getEpaasLocale);;
  }

  private postGetFeatureIndicator(response: boolean, epaasConfig: EpaasConfig): void {
    this.store.dispatch(this.featureIndicatorActions.setEPaas(response));
    if (response && epaasConfig) {
      this.epaasService.initializeEpaas(epaasConfig);
    }
  }

  public getItem(key: string): string | null {
    return this.epaasService.getItem(key);
  }

  public setItem(key: string, data: string) {
    this.epaasService.setItem(key, data);
  }

  public removeItem(key: string): void {
    this.epaasService.removeItem(key);
  }

  public isUsageAllowed(cookieCategory: string): Observable<boolean | undefined> {
    return this.store.select(s=> s.IsEpassInitialized?.booleanValue).pipe(map((res) => {
      if (res != undefined) {
        if (res)
          return this.epaasService.isUsageAllowed(cookieCategory);
        else { return }
      }
      else { return }
    }));
  }

}