import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'customerdigital-service-lib';
import { NotTermsGuard } from 'projects/_shared/_guards/not-terms-accepted';
import { TermsAcceptComponent } from './terms-accept/terms-accept.component';
import { TermsViewComponent } from './terms-view/terms-view.component';

const termsRoutes: Routes = [
    { path: 'terms/view', component: TermsViewComponent },
    { path: 'terms/accept', component: TermsAcceptComponent, canActivate: [NotTermsGuard, AuthGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(termsRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class TermsRoutingModule { }
