import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../shared/constants';
import { GuardService } from '../shared/helper-service/guard.service';
import { RouterService } from '../shared/helper-service/router.service';
import { UserService } from '../shared/helper-service/user.service';

@Injectable()
export class TermsAcceptedGuard implements CanActivate {
     constructor(private router: Router, private userService: UserService, private guardService: GuardService,
          private routerService: RouterService) { }

     canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
          if (this.userService.isTermsAccepted())
               return this.guardService.guardSucceded();
          else {
               this.routerService.navigateToTermsAccept(activatedRoute.queryParams[Constants.LanguageParam]);
               return false;
          }
     }
}